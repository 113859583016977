import React from "react";
import { Modal, Box, Button, Typography, IconButton} from '@mui/material';
import Close from "@mui/icons-material/Close";

const phraseStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  pl: 5,
  pr: 5,
  pt: 4,
  pb: 4
};

export const WarningModal = (props) =>{
  return (
    <Modal
      open={props.modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={phraseStyle}>
        <Box sx={{position: "absolute", top: '3px', right: '10px'}} onClick={props.handleClose}>
          <Close />
        </Box>
        <Box>
          {props.message}
        </Box>
        {props.buttonLength == 1 ?
          <Button
            variant='contained'
            color='warning'
            onClick={props.closeClick}
            disabled={props.isClose}
            sx={{borderRadius: 5, width: '100%', fontWeight: 'bold', mt: {xs: 0, md: 2}}}
          >
            {props.closeButton}
          </Button>
          :
          <Box sx={{textAlign: 'center', mt: 1}}>
            <Button
              variant='contained'
              color='secondary'
              onClick={props.cancelClick}
              disabled={props.isCancel}
              sx={{borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mr: 1}}
            >
              {props.cancelButton}
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={props.okClick}
              disabled={props.isOk}
              sx={{borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 0, md: 2}}}
            >
              {props.okButton}
            </Button>
          </Box>
        }
      </Box>
    </Modal>
  )
}