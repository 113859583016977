import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { InputExam, defaultExamValues, FileInfo, createFileInfo } from '../../../common/opportunity';
import { Button, TextField, Hidden, FormControl, InputLabel, Select, MenuItem, Grid, Paper, Link, Box, Typography} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { getFile, getOpportunityById, getTodos } from '../../../lib/api/admin';

export const AdminExamination = () =>{
  const { control, setValue, getValues} = useForm<InputExam>({defaultValues: defaultExamValues});
  const navigate = useNavigate();
  const [oppStatus, setOppStatus] = useState('');
  const [disableAdd, setDidableAdd] = useState(false);

  const handleGetOpportunity = async (pid: string) => {
    const res = await getOpportunityById(pid);
    const res3 = await getTodos();

    if (res.status === 200) {
      const todos = res3.data.records;
      const status = res.data.record.stagename;
      const todo = todos.find((t) => t.opportunitySfid == res.data.record.sfid);
      setValue('id', res.data.record.id);
      if(res.data.record.closedate) setValue('closedate', dayjs(res.data.record.closedate));
      if(res.data.record.exerciseprice) setValue('kiboukingakuSuchi', res.data.record.exerciseprice ? res.data.record.exerciseprice.toLocaleString() : '');
      if(res.data.record.type) setValue('type', res.data.record.type);
      if(res.data.record.note) setValue('goyoubou', res.data.record.note);
      if(res.data.record.totalamountofreceivables) setValue('seikyuukingaku', res.data.record.totalamountofreceivables ? res.data.record.totalamountofreceivables.toLocaleString() : '');
      if(res.data.record.kaisyuyoteibi) setValue('kaisyuyoteibi', dayjs(res.data.record.kaisyuyoteibi));
      setOppStatus(res.data.record.stagename);

      if(res.data.record.selectedAccountsReceivables.length > 0){
        const count = res.data.record.selectedAccountsReceivables.length - 1;
        let companyString = res.data.record.selectedAccountsReceivables[0].companyname ? res.data.record.selectedAccountsReceivables[0].companyname : res.data.record.selectedAccountsReceivables[0].name;
        if(count > 0) companyString += ' 他' + count + '社'
        setValue('company', companyString);
      }

      setDidableAdd((status == 'キャンセル' || status == 'NG' || status == '回収済み（仮）' || ((status == 'CS承認依頼' || status == '契約締結済み' || status == '実行済み') && !todo) ) ? true : false)
    }
  }

  const {id} = useParams();
  useEffect(() => {
    if(id) {
      handleGetOpportunity(id);
    }
  }, [])

  const onClickAddFiles = () =>{
    const val = getValues();
    navigate(`/admin/file_addition/${val.id}`);
  }

  const onClickCancel = () =>{
    const val = getValues();
    navigate(`/admin/status_cancel/confirm/${val.id}`);
  }

  const handleFileClick = async (s3_id) =>{
    const res = await getFile(s3_id);
    if(res.status == 200){
      open(res.data.url, '_blank');
    }
  }

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <form id="user-confirm-form" >
      <Controller
        control={control}
        name="user_id"
        render={({ field }) => (
          <Hidden/>
        )}
      />
      <Box sx={{
        borderLeft: "5px solid #D16960",
        textAlign: 'left'
      }}>
        <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>申請情報</Typography>
      </Box>
      <Box
        sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
      >
        <Controller
          control={control}
          name='closedate'
          disabled
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  調達希望日
                </InputLabel>
              </Grid>
              <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DatePicker
                      {...field}
                      format={'YYYY/MM/DD'}
                      onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          name="kiboukingakuSuchi"
          disabled
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  買取（希望）額
                </InputLabel>
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 1, md: 2}}}
                  margin="normal"
                  color="secondary"
                />
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          name="type"
          disabled
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  契約方法
                </InputLabel>
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                  <Select
                    {...field}
                    color="secondary"
                  >
                    <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                    <MenuItem value={'2者間'}>2者間</MenuItem>
                    <MenuItem value={'3者間'}>3者間</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          disabled
          name="goyoubou"
          defaultValue=""
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  ご要望等
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 1, md: 2}}}
                  margin="normal"
                  color="secondary"
                />
              </Grid>
            </Grid>
          )}
        />
      </Box>
      <Box sx={{
        borderLeft: "5px solid #D16960",
        textAlign: 'left',
        mt: 2
      }}>
        <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>売掛先情報</Typography>
      </Box>
      <Box sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}>
        <Controller
          disabled
          control={control}
          name={'company'}
          defaultValue=""
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  売掛先名
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 1, md: 2}}}
                  margin="normal"
                  color="secondary"
                />
              </Grid>
            </Grid>
          )}
        />
        <Controller
          disabled
          control={control}
          name={'seikyuukingaku'}
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  請求金額
                </InputLabel>
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 1, md: 2}}}
                  margin="normal"
                  color="secondary"
                />
              </Grid>
              <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
            </Grid>
          )}
        />
        <Controller
          disabled
          control={control}
          name={'kaisyuyoteibi'}
          render={({ field }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  売掛先からの入金予定日
                </InputLabel>
              </Grid>
              <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DatePicker
                      {...field}
                      format={'YYYY/MM/DD'}
                      onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
            </Grid>
          )}
        />
      </Box>
      </form>
      <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
        <Button
          variant='contained'
          color='primary'
          disabled={disableAdd}
          onClick={() =>{onClickAddFiles()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >
          追加書類提出
        </Button>
        { oppStatus == '申請中' ?
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>{onClickCancel()}}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
          >
            申請をキャンセル
          </Button>
          :
          <></>
        }
      </Box>
    </Box>
  );
}