import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Paper, Typography, Button, IconButton } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CloseIcon from '@mui/icons-material/Close';

export const DragDropFileUpload = ({onFileUpload, input_id, inputValue, disabled=false})=> {
  const [dragOver, setDragOver] = useState(false);
  const inputRef = useRef<any>(null);
  const [inputFiles, setInputFiles] = useState<FileList | null>(inputValue);
  const [filenames, setFilenames] = useState<String[]>([]);
  const [selectedFileArray, setSelectedFileArray] = useState<File[]>([]);

  useEffect(() =>{
    if(!inputValue)return;
    setSelectedFileArray([...Array.from(inputValue)]);
    const names = [];
    for(let i=0; i<inputValue.length; i++){
      names.push(inputValue[i].name);
    }
    setFilenames(names);
  }, [inputValue])

  // const selectedFileArray: File[] = useMemo(() => {
  //   return inputFiles ? [...Array.from(inputFiles)] : [];
  // }, [inputFiles]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const _dataTransfer = new DataTransfer();
      const newFileArray = [
        ...selectedFileArray,
        ...Array.from(event.dataTransfer.files),
      ].filter(
        (file, index, self) =>
          self.findIndex((f) => f.name === file.name) === index // 重複を削除
      );
      newFileArray.forEach((file) => _dataTransfer.items.add(file));
      inputRef.current.files = _dataTransfer.files;
      setInputFiles(_dataTransfer.files);
      setDragOver(false);
      onFileUpload(inputRef.current.files);
    },
    [selectedFileArray]
  );

  const handleChange = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const _dataTransfer = new DataTransfer();
      const newFileArray = [
        ...selectedFileArray,
        ...Array.from(event.target.files),
      ].filter(
        (file, index, self) =>
          self.findIndex((f) => f.name === file.name) === index
      );
      newFileArray.forEach((file) => _dataTransfer.items.add(file));
      inputRef.current.files = _dataTransfer.files;
      setInputFiles(_dataTransfer.files);
      onFileUpload(event.target.files);
    },
    [selectedFileArray]
  );

  const removeFile = (index) =>{
    const _dataTransfer = new DataTransfer();
    selectedFileArray.splice(index, 1);
    selectedFileArray.forEach((file) => _dataTransfer.items.add(file));
    inputRef.current.files = _dataTransfer.files;
    setInputFiles(_dataTransfer.files);
    onFileUpload(inputRef.current.files);
  }

  const viewFile = (index) =>{
    const reader = new FileReader();
    reader.onload = async function(e) {
      if(e.target?.result){
        const blob = await (await fetch(e.target.result)).blob();
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    }
    reader.readAsDataURL(inputRef.current.files[index]);
  }

  const handleClickSelectFile = ()=>{
    if(inputRef.current == null) return;
    inputRef.current.click();
  };

  const input_id_str = `raised-button-file${input_id}`

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
        padding: 10,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
      }}
    >
      { !disabled 
        ? <Box><input
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          id={input_id_str}
          multiple
          type="file"
          onChange={handleChange}
          ref={inputRef}
        />
        <label htmlFor={input_id_str}>
          <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>ここにドラッグ&ドロップ、または</Typography>
            <Button 
              startIcon={<CropOriginalIcon />}
              variant='contained'
              color='secondary'
              onClick={handleClickSelectFile}
              sx={{borderRadius: 5, fontWeight: 'bold'}}
            >
              ファイルを選択
            </Button>
            <Typography>(PDF、JPG、PNG)</Typography>
          </Box>
        </label>
        {filenames.map((file, idx) =>{
          return (
            <Box sx={{display: 'ruby'}} key={idx}>
              <Typography key={idx} onClick={(e) => viewFile(idx)} sx={{textDecoration: 'underline'}}>{file}</Typography>
              <IconButton onClick={(e) => removeFile(idx)}>
                <CloseIcon />
              </IconButton>
            </Box>
          );
        })}
        </Box>
        : <Box>{filenames.map((row, idx) =>{
          return (
            <Typography key={idx}>{row}</Typography>
          );
        })}
        </Box>
      }
    </Paper>
  );
}