import React, { useState } from 'react';
import { Button, TextField, Hidden, FormControl, InputLabel, Select, MenuItem, Grid, Paper, Link, Box, Typography, FormHelperText} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { cancelOpportunity, getOpportunityById} from "../../lib/api/opportunity"
import { InputCancelExam, InputExam, defaultCancelExamValues } from '../../common/opportunity';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import Loading from "../../components/Loading";
import { ErrorModal } from '../../components/ErrorModal';
import { getProfile } from '../../lib/api/profile';

export const ExaminationCancel = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm<InputCancelExam>({defaultValues: defaultCancelExamValues});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [oppStatus, setOppStatus] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleGetOpportunity = async (pid: string) => {
    const res = await getOpportunityById(pid);
    const res2 = await getProfile();
    if(res2.status === 200){
      if(res2.data.record.objClass != "Salesforce::Account"){
        navigate('/status_detail/' + res.data.record.id);
        return;
      }
    }
    if (res.status === 200) {
      if(res.data.record.stagename != '申請中'){
        navigate('/status_detail/' + res.data.record.id);
        return;
      }
      if(res.data.record.id) setValue('id', res.data.record.id);
      if(res.data.record.closedate) setValue('closedate', dayjs(res.data.record.closedate));
      if(res.data.record.exerciseprice) setValue('kiboukingakuSuchi', res.data.record.exerciseprice ? res.data.record.exerciseprice.toLocaleString() :'');
      if(res.data.record.type) setValue('type', res.data.record.type);
      if(res.data.record.note) setValue('goyoubou', res.data.record.note);
      if(res.data.record.totalamountofreceivables) setValue('seikyuukingaku', res.data.record.totalamountofreceivables ? res.data.record.totalamountofreceivables.toLocaleString() : '');
      if(res.data.record.kaisyuyoteibi) setValue('kaisyuyoteibi', dayjs(res.data.record.kaisyuyoteibi));
      setOppStatus(res.data.record.stagename);

      if(res.data.record.selectedAccountsReceivables.length > 0){
        const count = res.data.record.selectedAccountsReceivables.length - 1;
        let companyString = res.data.record.selectedAccountsReceivables[0].companyname ? res.data.record.selectedAccountsReceivables[0].companyname : res.data.record.selectedAccountsReceivables[0].name;
        if(count > 0) companyString += ' 他' + count + '社'
        setValue('company', companyString);
      }
    }
  }

  const {id} = useParams();

  useEffect(() => {
    setValue('user_id', Cookies.get('currentUserId'));
    if(id) {
      handleGetOpportunity(id);
    }
  }, [])


  const onSubmit: SubmitHandler<InputCancelExam> = async (evt) =>{
    if(isLoading) return;
    setIsLoading(true);
    const val = getValues();
    try {
      const res = await cancelOpportunity(val.id, evt.cancelReason, evt.cancelBikou);
      setIsLoading(false);
      if(res.status === 200){
        navigate("/status_cancel/completed/", {state: val.id});
      }
    }catch(e){
      console.log(e);
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const onClickCancel = ()=>{
    const val = getValues();
    navigate("/status_detail/" + val.id);
  }; 
  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <p>下記申請をキャンセルしますか？</p>
      <form id="user-edit-form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left'
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>キャンセル理由</Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name="cancelReason"
            rules={{required: 'キャンセル理由を入力してください。'}}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    キャンセル理由
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}} error={fieldState.invalid}>
                    <Select
                      {...field}
                      color="secondary"
                    >
                      <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                      <MenuItem value={'必要書類が多い'}>必要書類が多い</MenuItem>
                      <MenuItem value={'希望金額が調達できない'}>希望金額が調達できない</MenuItem>
                      <MenuItem value={'希望日に間に合わない'}>希望日に間に合わない</MenuItem>
                      <MenuItem value={'手数料が高い'}>手数料が高い</MenuItem>
                      <MenuItem value={'他社を利用する'}>他社を利用する</MenuItem>
                      <MenuItem value={'対応に不満があった'}>対応に不満があった</MenuItem>
                      <MenuItem value={'必要なくなった'}>必要なくなった</MenuItem>
                      <MenuItem value={'その他'}>その他</MenuItem>
                    </Select>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="cancelBikou"
            rules={{validate: (value, formValues) =>{
              if(formValues.cancelReason == 'その他' && !value) return 'キャンセル理由(その他)を入力してください。'
            }}}
            defaultValue=""
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    キャンセル理由(その他)
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <Controller
          control={control}
          name="user_id"
          render={({ field }) => (
            <Hidden/>
          )}
        />
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left'
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>申請情報</Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='closedate'
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    調達希望日
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="kiboukingakuSuchi"
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    買取（希望）額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="type"
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    契約方法
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                    <Select
                      {...field}
                      color="secondary"
                    >
                      <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                      <MenuItem value={'2者間'}>2者間</MenuItem>
                      <MenuItem value={'3者間'}>3者間</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            disabled
            name="goyoubou"
            defaultValue=""
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    ご要望等
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left',
          mt: 2
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>売掛先情報</Typography>
        </Box>
        <Box sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}>
          <Controller
            disabled
            control={control}
            name={'company'}
            defaultValue=""
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    売掛先名
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            )}
          />
          <Controller
            disabled
            control={control}
            name={'seikyuukingaku'}
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    請求金額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            disabled
            control={control}
            name={'kaisyuyoteibi'}
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    売掛先からの入金予定日
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
        </Box>
        <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => onClickCancel()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            やめる
          </Button>
          { oppStatus == '申請中' ?
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
            >
              キャンセルする
            </Button>
            :
            <></>
          }
        </Box>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  );
}