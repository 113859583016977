import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContextType, useAuthContext } from "../../providers/AuthUser";
import { Container, Typography} from '@mui/material';
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";
import { ErrorModal } from "../../components/ErrorModal";
import { getSession } from "../../lib/api/admin_user";

export const AdminLogin = () =>{
  const authUser:AuthContextType = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const email = query.get('email');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const setToken = async () =>{
    const res = await getSession();
    const input = document.getElementsByName('authenticity_token');
    input[0].value = Cookies.get('CSRF-TOKEN');
  }

  const navigate = useNavigate();

  useEffect(() =>{
    setToken();
  }, []);

  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        border: 'solid',
        borderColor: '#E1DED0',
        pt: 5,
        pb: 5
      }}
      >
      <form id='sign_in_form' method="post" action="/omniauth/saml">
        <button
          type="submit"
          data-turbo={false}
          className="saml-button"
        >
          Salesforceでログイン
        </button>
        <input type="hidden" name="authenticity_token" value=''></input>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
}
