import React, {useState, useEffect} from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { sendProfileSms, updateProfile } from "../../lib/api/profile"
import { Button, TextField, Grid, InputLabel, Box, Link, Typography} from '@mui/material';
import { Input, defaultValues } from '../../common/profile';
import dayjs from 'dayjs';
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import { ErrorModal } from '../../components/ErrorModal';

export const ProfileConfirm = () =>{
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<Input>(location.state);
  const { control, handleSubmit, setValue, getValues} = useForm<Input>({defaultValues});
  const navigate = useNavigate();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [phonenum, setPhonenum] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const isMessage = query.get('message');

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  useEffect(() => {
    if(id != null){
      setValue('firstname', userInfo.firstname);
      setValue('lastname', userInfo.lastname);
      setValue('state', userInfo.state);
      setValue('email', userInfo.email);
      setValue('company', userInfo.company);
      setValue('companynameFurigana', userInfo.companynameFurigana)
      setValue('mobilephone', userInfo.mobilephone);
      setValue('city', userInfo.city);
      setValue('street', userInfo.street);
      setValue('postalcode', userInfo.postalcode);
      setValue('kind', userInfo.kind);
      setValue('industry', userInfo.industry);
      setValue('businessPostalcode', userInfo.businessPostalcode);
      setValue('businessState', userInfo.businessState);
      setValue('businessCity', userInfo.businessCity);
      setValue('businessStreet', userInfo.businessStreet);
      setValue('businessOther', userInfo.businessOther);
      setValue('corporatenumberAccount', userInfo.corporatenumberAccount);
      setValue('position', userInfo.position);
      setValue('firstnamekana', userInfo.firstnamekana);
      setValue('lastnamekana', userInfo.lastnamekana);
      setValue('raPostalcode', userInfo.raPostalcode);
      setValue('raState', userInfo.raState);
      setValue('raCity', userInfo.raCity);
      setValue('raStreet', userInfo.raStreet);
      setValue('raOther', userInfo.raOther);
      setValue('mobilephone', userInfo.mobilephone);
      setValue('seibetsu', userInfo.seibetsu);
      setValue('birthdate', dayjs(userInfo.birthdate.$d));
      setValue('bankname', userInfo.bankname);
      setValue('branchname', userInfo.branchname);
      setValue('accounttype', userInfo.accounttype);
      setValue('accounttypeOther', userInfo.accounttypeOther);
      setValue('accountnumber', userInfo.accountnumber);
      setValue('accountholder', userInfo.accountholder);
      setValue('accountnameKana', userInfo.accountnameKana);
      setValue('identification', userInfo.identification);
      setValue('bankcode', userInfo.bankcode);
      setValue('bankcodeBusinesstypecode', userInfo.bankcodeBusinesstypecode);
      setValue('bankcodeBusinesstype', userInfo.bankcodeBusinesstype);
      setValue('bankcodeHiragana', userInfo.bankcodeHiragana);
      setValue('banknameFullwidthkana', userInfo.banknameFullwidthkana);
      setValue('banknameHalfwidthkana', userInfo.banknameHalfwidthkana);
      setValue('branchcode', userInfo.branchcode);
      setValue('branchnameHiragana', userInfo.branchnameHiragana);
      setValue('branchnameFullwidthkana', userInfo.branchnameFullwidthkana);
      setValue('branchnameHalfwidthkana', userInfo.branchnameHalfwidthkana);
      setValue('tashariyoureki', userInfo.tashariyoureki);
      setValue('othercompaniesusedinthepast', userInfo.othercompaniesusedinthepast);
      setValue('id', id);
      setPhonenum(userInfo.mobilephone);
    }else{
      // 別画面に遷移させる。
      // ページが存在しません。
      // 再度やり直してください。など
    }
  }, []);

  const onSubmit: SubmitHandler<Input> = async (evt) =>{
    if(id == null) return;
    if(isLoading) return;
    setIsLoading(true);
    try{
      if(evt.birthdate) evt.birthdate = evt.birthdate.format('YYYY-MM-DD');
      const res = await updateProfile(evt);
      setIsLoading(false);
      if(res.status===200){
        const headerName =  `${evt.company}`;
        const users = Cookies.get("_users") ? JSON.parse(Cookies.get("_users")) : [];
        const indx = users.findIndex((u) => u._uid == res.headers['uid']);
        users[indx]._header_text = headerName;
        Cookies.set("_users", JSON.stringify(users));
        Cookies.set("_header_text", headerName);
        navigate("/myprofile/edit/completed/" + res.data.record.id);
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const handleReSend = async(evt) =>{
    if(isLoading) return;
    const values = getValues();
    setIsLoading(true);
    try{
      const res = await sendProfileSms(values.mobilephone);
      setIsLoading(false);
      if(res.status == 200){
        setErrorMessage(<Typography>再送しました。</Typography>);
        handleOpenErrorModal();
      }else{
        setErrorMessage(<Typography>再送に失敗しました。再度、実行してください。</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>再送に失敗しました。再度、実行してください。</Typography>);
      handleOpenErrorModal();
    }
  }

  return (
    <Box sx={{
      backgroundColor: {xs: "#FFFFFF"},
      borderRadius: {xs: 2},
      width: {md: '70%', xs: '100%'},
      margin: 'auto',
      pr: 2,
      pl: 2,
      pt: 1,
      pb: 1
    }}>
      <Box component='h2'>認証コードを入力</Box>
      <Typography sx={{textAlign: 'left'}}>
        {phonenum} 宛にSMSを送信しました。
      </Typography>
      <Typography sx={{textAlign: 'left'}}>
        SMSに記載された4桁の数字を入力してください。
      </Typography>
      <Typography sx={{textAlign: 'left'}}>
        ※しばらく経ってもSMSが届かない場合、迷惑メールのフィルター設定でSMSの受信拒否設定などがされていないかをご確認ください。
      </Typography>
      <Box sx={{display: isMessage ? 'block' : 'none'}}>
        <Typography sx={{textAlign: 'left', fontWeight: 'bold', mt: 1}}>
          審査を申し込むには、SMS認証後に再度「かんたん本人確認」を実施してください。
        </Typography>
      </Box>
      <form id="user-confirm-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <TextField
              {...field}
              sx={{mt: {xs: 0, md: 2}}}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
        <Box></Box>
        <Link onClick={handleReSend} underline="hover">認証コードを再送する</Link>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  )
}