import React from 'react';
import { Box, Button, FormControl, Grid, Hidden, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getOpportunityById} from "../../lib/api/opportunity"
import { InputExam, defaultExamValues, mappingExamOpportunity } from '../../common/opportunity';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
interface Opportunity {
  applicantName: string;
  desiredAmount: number;
}

export const ExaminationCancelSuccess = () =>{
  const location = useLocation();
  const { control, setValue, getValues} = useForm<InputExam>({defaultValues: defaultExamValues});
  const [opportunityId, setOpportunityId] = useState<string>(location.state);
  const [opportunity, setOpportunity] = useState<InputExam>(defaultExamValues);
  const navigate = useNavigate();

  const handleGetOpportunity = async (pid: string) => {
    const res = await getOpportunityById(pid);
    if (res.status === 200) {
      // TODO:取得した値で設定したい。
      if(res.data.record.id) setValue('id', res.data.record.id);
      if(res.data.record.closedate) setValue('closedate', dayjs(res.data.record.closedate));
      if(res.data.record.exerciseprice) setValue('kiboukingakuSuchi', res.data.record.exerciseprice ? res.data.record.exerciseprice.toLocaleString() : '');
      if(res.data.record.type) setValue('type', res.data.record.type);
      if(res.data.record.note) setValue('goyoubou', res.data.record.note);
      if(res.data.record.totalamountofreceivables) setValue('seikyuukingaku', res.data.record.totalamountofreceivables ? res.data.record.totalamountofreceivables.toLocaleString() : '');
      if(res.data.record.kaisyuyoteibi) setValue('kaisyuyoteibi', dayjs(res.data.record.kaisyuyoteibi));
      if(res.data.record.selectedAccountsReceivables.length > 0){
        const count = res.data.record.selectedAccountsReceivables.length - 1;
        let companyString = res.data.record.selectedAccountsReceivables[0].companyname ? res.data.record.selectedAccountsReceivables[0].companyname : res.data.record.selectedAccountsReceivables[0].name;
        if(count > 0) companyString += ' 他' + count + '社'
        setValue('company', companyString);
      }
    }
  }

  useEffect(() => {
    if(!opportunityId) return;
    handleGetOpportunity(opportunityId);
  }, [])

  const onClickFactoring = () =>{
    navigate("/factoring/top");
  }

  const onClickHome = () =>{
    navigate("/top");
  }

  const onClickCancel = ()=>{
    navigate("/status_list");
  }; 
  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <p>下記申請のキャンセルが完了しました</p>
      <Controller
          control={control}
          name="user_id"
          render={({ field }) => (
            <Hidden/>
          )}
        />
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left'
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>申請情報</Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='closedate'
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    調達希望日
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="kiboukingakuSuchi"
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    買取（希望）額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="type"
            disabled
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    契約方法
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                    <Select
                      {...field}
                      color="secondary"
                    >
                      <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                      <MenuItem value={'2者間'}>2者間</MenuItem>
                      <MenuItem value={'3者間'}>3者間</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            disabled
            name="goyoubou"
            defaultValue=""
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    ご要望等
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left',
          mt: 2
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>売掛先情報</Typography>
        </Box>
        <Box sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}>
          <Controller
            disabled
            control={control}
            name={'company'}
            defaultValue=""
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    売掛先名
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            )}
          />
          <Controller
            disabled
            control={control}
            name={'seikyuukingaku'}
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    請求金額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            disabled
            control={control}
            name={'kaisyuyoteibi'}
            render={({ field }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    売掛先からの入金予定日
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
        </Box>
        <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onClickFactoring()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            審査の申し込み
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onClickCancel()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            申請履歴を確認する
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => onClickHome()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            ホームに戻る
          </Button>
        </Box>
    </Box>
  );
}